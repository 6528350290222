import fetch from "@/utils/service";

export function getMeetingList(params) {
  return fetch({
    url: "/km/imeeting/km_imeeting_equipment/xxfMeeting.do?method=getKmImeetingResPage",
    params: params,
    headers: {
      "User-Agent":
        "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.45 Safari/537.36",
      b: "a",
    },
  });
}
export function getMeetingDetail(params) {
  return fetch({
    url: "/km/imeeting/km_imeeting_equipment/xxfMeeting.do?method=getKmImeetingMainDetail",
    params: params,
  });
}

export function verifyPassword(params) {
  return fetch({
    url: "/km/imeeting/km_imeeting_equipment/xxfMeeting.do?method=checkKmImeetingPwd",
    params: params,
  });
}
