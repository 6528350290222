<template>
  <div class="meetingDetail">
    <div class="returnBox" @click="goBack"></div>
    <div class="sysTime">{{ time }}</div>
    <div class="meetingInfoBox">
      <div class="meetingInfo">
        <div
          :class="dataSouce.roomStatus == 0 ? 'activeLeftBox' : 'leftBox'"
        ></div>
        <div :class="dataSouce.roomStatus == 0 ? 'activeMidBox' : 'midBox'">
          {{ dataSouce.roomName ? dataSouce.roomName + "-" : ""
          }}{{
            dataSouce.roomStatus == 0
              ? "空闲中"
              : dataSouce.roomStatus == 1
              ? "会议中"
              : ""
          }}
        </div>
        <div
          :class="dataSouce.roomStatus == 0 ? 'activeRightBox' : 'rightBox'"
        ></div>
      </div>
    </div>
    <div class="contentBox">
      <div class="firstLineBox">
        <span>会议主题</span>
        <div class="lineContent">
          <span>{{ dataSouce.meetSubject || "-" }}</span>
        </div>
      </div>
      <div class="lineBox">
        <span>开始时间</span>
        <div class="midLineContent">
          <span>{{ dataSouce.meetStart || "-" }}</span>
        </div>
        <span class="endTime">结束时间</span>
        <div class="midLineContent">
          <span>{{ dataSouce.meetEnd || "-" }}</span>
        </div>
      </div>
      <div class="lineBox">
        <span class="lastBox">预约人</span>
        <div class="lineContent">
          <span
            >{{ dataSouce.meetOrderPerson ? dataSouce.meetOrderPerson : "-"
            }}{{
              dataSouce.meetOrderPersonDept
                ? "(" + dataSouce.meetOrderPersonDept + ")"
                : "-"
            }}</span
          >
        </div>
      </div>
    </div>
    <div class="timeBox">
      <div class="timeLayout">
        <div class="timeReduce" @click="timeReduce"></div>
        <div
          class="timePart"
          v-for="(item, index) in TimelistChange"
          :key="index"
        >
          <span
            :class="item.roomStatus == 1 ? 'activeBlock' : 'timeBlock'"
            @click="timeBlockClick(item.time)"
            >{{ item.time }}</span
          >
        </div>
        <div class="timeAdd" @click="timeAdd"></div>
      </div>
    </div>
    <div class="dayBox">
      <div class="dayLayout">
        <div class="dayReduce" @click="dayReduce"></div>
        <div class="dayPart">
          <span class="dayBlock">{{ DayChange }}</span>
        </div>
        <div class="dayAdd" @click="dayAdd"></div>
      </div>
    </div>
    <div class="tip">红框白字为被占用时间段，不可预约</div>
    <van-dialog
      v-model="isShow"
      show-cancel-button
      title="请输入密码"
      @cancel="cancel"
      @confirm="confirm"
    >
      <div class="inputMargin">
        <van-field v-model="userPass" label placeholder type="password" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import moment from "moment";
import md5 from "js-md5";
import { getMeetingDetail, verifyPassword } from "@/api/index";
export default {
  name: "meetingDetail",
  created() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
    this.initTimePart();
    this.initDayPart();
    this.getDetail();
  },
  watch: {
    time(val) {
      // console.log('val',moment(val).second());
      let index = val.indexOf(":");
      let temp = val.substring(index + 1);
      if (temp == "00:00" || temp == "30:00") {
        this.initTimePart();
        this.initDayPart();
        this.getDetail();
      }
    },
  },
  data() {
    return {
      time: "",
      dataSouce: {
        timeList: [
          {
            time: "9:00", // 时间 HH:mm,
            roomStatus: 1, // 1-会议中 0-空闲中
          },
        ],
      },
      initTimelist: [],
      TimelistChange: [],
      initDay: "",
      DayChange: "",
      isShow: false,
      userPass: "",
    };
  },
  methods: {
    updateTime() {
      var cd = new Date();
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ":" +
        this.zeroPadding(cd.getMinutes(), 2) +
        ":" +
        this.zeroPadding(cd.getSeconds(), 2);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    halfChange(currentTime) {
      let Time = "";
      let minute = moment(currentTime).minute();
      if (minute / 30 < 1) {
        Time = moment(currentTime).format("YYYY-MM-DD HH:00");
      } else {
        Time = moment(currentTime).format("YYYY-MM-DD HH:30");
      }
      return Time;
    },
    initTimePart() {
      let addMinutes = 0;
      this.initTimelist = [];
      this.TimelistChange = [];
      for (let index = 0; index < 9; index++) {
        let nextTime = moment().add(addMinutes, "minutes");
        let finalTime = this.halfChange(nextTime);
        this.initTimelist.push(finalTime);
        addMinutes = addMinutes + 30;
      }
      this.TimelistChange = this.timeTurnTo(this.initTimelist);
    },
    timeReduce() {
      let start = this.initTimelist[0];
      let firstTime = moment(start)
        .subtract(30, "minutes")
        .format("YYYY-MM-DD HH:mm");
      this.initTimelist[0] = firstTime;
      for (let index = 1; index < 9; index++) {
        let nextTime = moment(this.initTimelist[index])
          .subtract(30, "minutes")
          .format("YYYY-MM-DD HH:mm");
        this.initTimelist[index] = nextTime;
      }
      this.TimelistChange = this.timeTurnTo(this.initTimelist);
      this.initDayPart();
      this.getDetail();
    },
    timeAdd() {
      let start = this.initTimelist[0];
      let firstTime = moment(start)
        .add(30, "minutes")
        .format("YYYY-MM-DD HH:mm");
      this.initTimelist[0] = firstTime;
      for (let index = 1; index < 9; index++) {
        let nextTime = moment(this.initTimelist[index])
          .add(30, "minutes")
          .format("YYYY-MM-DD HH:mm");
        this.initTimelist[index] = nextTime;
      }
      this.TimelistChange = this.timeTurnTo(this.initTimelist);
      this.initDayPart();
      this.getDetail();
    },
    timeTurnTo(timeList) {
      let list = timeList.map((element) => {
        return { time: moment(element).format("HH:mm") };
      });
      return list;
    },
    initDayPart() {
      console.log("this.initTimelist[0]", this.initTimelist[0]);
      this.initDay = moment(this.initTimelist[0]).format("YYYY-MM-DD");
      this.DayChange = this.dayTurnTo(this.initDay);
    },
    dayReduce() {
      this.initDay = moment(this.initDay)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      this.DayChange = this.dayTurnTo(this.initDay);
      this.getDetail();
    },
    dayAdd() {
      this.initDay = moment(this.initDay).add(1, "days").format("YYYY-MM-DD");
      this.DayChange = this.dayTurnTo(this.initDay);
      this.getDetail();
    },
    dayTurnTo(dayTime) {
      return moment(dayTime).format("YYYY-MM-DD");
    },
    goBack() {
      this.isShow = true;
    },
    getDetail(time) {
      let queryData = {
        placeId: this.$route.query.placeId,
        beginTime: time ? time : this.TimelistChange[0].time,
        interval: 4.5,
        queryDate: this.DayChange,
        sign: md5(this.$route.query.placeId),
      };
      getMeetingDetail(queryData).then((res) => {
        this.dataSouce = res.data.data;
        this.dataSouce.timeList.forEach((item) => {
          this.TimelistChange.forEach((element) => {
            if (element.time == item.time) {
              element.roomStatus = item.roomStatus;
            }
          });
        });
        // if(this.TimelistChange[0].time=='00:00'){
        // this.dayAdd()
        // }
        // console.log('this.TimelistChange[0].time', this.TimelistChange[0].time);
      });
    },

    postPassward() {
      verifyPassword({ pwd: this.userPass, sign: md5(this.userPass) }).then(
        (res) => {
          console.log("res", res);
          if (res.data.status == 200) {
            this.$router.push({
              path: "/",
            });
          } else {
            this.$toast.fail("对不起密码错误!");
          }
        }
      );
    },
    timeBlockClick(time) {
      this.getDetail(time);
    },
    confirm() {
      this.postPassward();
    },
    cancel() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
.meetingDetail {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("../assets/pic_meeting room_meeting bg@2x.png") no-repeat;
  background-size: 100%;
  .returnBox {
    width: 136px;
    height: 60px;
    background: url("../assets/btn_meeting room_return@2x.png") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 23px;
    left: 60px;
  }
  .sysTime {
    width: 133px;
    height: 45px;
    color: #ffffff;
    font-size: 32px;
    position: absolute;
    top: 28px;
    right: 60px;
  }
  .contentBox {
    width: 1660px;
    height: 503px;
    position: absolute;
    margin: 226px 130px 0 130px;
    .firstLineBox {
      display: flex;
      align-items: center;
      width: 1460px;
      height: 90px;
      padding: 93px 108px 0 92px;
      font-size: 40px;
      color: #ffffff;
    }
    .lineBox {
      @extend .firstLineBox;
      padding: 40px 108px 0 92px;
    }
    .lineContent {
      margin-left: 40px;
      width: 1260px;
      height: 90px;
      background: #fff3e6;
      border-radius: 10px;
      opacity: 0.9;
      color: #ff5e00;
      font-size: 38px;
      line-height: 90px;
      span {
        margin-left: 30px;
      }
    }
    .endTime {
      margin-left: 120px;
    }
    .midLineContent {
      @extend .lineContent;
      width: 245px;
    }
    .lastBox {
      letter-spacing: 13px;
    }
  }
  @mixin btnImg($url) {
    width: 70px;
    height: 70px;
    background: url($url) no-repeat;
    background-size: 100%;
  }
  .Reduce {
    @include btnImg("../assets/btn_meeting room_left arrow@2x.png");
  }
  .Add {
    @include btnImg("../assets/btn_meeting room_right arrow@2x.png");
  }
  .timeBox {
    width: 1660px;
    height: 110px;
    background: rgb(248, 193, 163);
    border-radius: 20px;
    position: absolute;
    margin: 759px 130px 0 130px;
    .timeLayout {
      display: flex;
      width: 1610px;
      height: 70px;
      margin: 20px 25px;
    }
    .timeReduce {
      @extend .Reduce;
    }
    .timePart {
      .timeBlock {
        display: block;
        width: 150px;
        height: 70px;
        margin-left: 12px;
        border-radius: 5px;
        line-height: 70px;
        text-align: center;
        font-size: 34px;
        color: #ff5e00;
        background: #fff3e6;
      }
    }
    .timeAdd {
      @extend .Add;
      margin-left: 12px;
    }
  }
  .dayBox {
    width: 619px;
    height: 110px;
    background: rgb(248, 193, 163);
    border-radius: 20px;
    position: absolute;
    margin: 899px 130px 0 1171px;
    .dayLayout {
      display: flex;
      width: 569px;
      height: 70px;
      margin: 20px 25px;
    }
    .dayReduce {
      @extend .Reduce;
    }
    .dayPart {
      .dayBlock {
        display: block;
        width: 405px;
        height: 70px;
        margin-left: 12px;
        border-radius: 5px;
        line-height: 70px;
        text-align: center;
        font-size: 34px;
        color: #ff5e00;
        background: #fff3e6;
      }
    }
    .dayAdd {
      @extend .Add;
      margin-left: 12px;
    }
  }
  .tip {
    position: absolute;
    margin: 930px 0 0 130px;
    font-size: 34px;
    color: #e80505;
  }
  .meetingInfoBox {
    position: absolute;
    margin-top: 148px;
    width: 100vw;
  }
  .meetingInfo {
    width: 1412px;
    height: 120px;
    margin: 0 auto;
    line-height: 120px;
    font-size: 44px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    .leftBox {
      width: 70px;
      height: 120px;
      float: left;
      background: url("../assets/title_left.png") no-repeat;
      background-size: 100% 100%;
    }
    .activeLeftBox {
      @extend .leftBox;
      background: url("../assets/title_left_yellow.png") no-repeat;
      background-size: 100% 100%;
    }
    .midBox {
      display: inline;
      height: 120px;
      float: left;
      background: url("../assets/title_middle.png") no-repeat;
      background-size: 100% 100%;
    }
    .activeMidBox {
      @extend .midBox;
      background: url("../assets/title_middle_yellow.png") no-repeat;
      background-size: 100% 100%;
    }
    .rightBox {
      width: 70px;
      height: 120px;
      float: left;
      background: url("../assets/title_right.png") no-repeat;
      background-size: 100% 100%;
    }
    .activeRightBox {
      @extend .rightBox;
      background: url("../assets/title_right_yellow.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.van-dialog {
  width: 840px;
  height: 494px;
  background: #ffffff;
  border-radius: 8px;
}
.van-dialog__header {
  font-size: 34px;
  font-weight: 700;
  color: #222222;
  line-height: 34px;
  padding-top: 80px;
}

.van-dialog__content {
  margin: 0 auto;
  padding: 20px 0;
}

.inputMargin {
  .van-field__control {
    width: 680px;
    height: 80px;
    padding-left: 20px;
    background: #fef4ed;
    font-size: 34px;
    margin: 40px auto;
  }
}
.van-dialog__cancel {
  width: 320px !important;
  height: 70px;
  background: #fef4ed;
  border-radius: 6px;
  border: 1px solid #ff5e00;
  font-size: 30px;
  color: #ff5e00;
  font-weight: 700;
}
.van-dialog__confirm {
  margin-left: 40px;
  width: 320px !important;
  height: 70px;
  background: linear-gradient(90deg, #ff9615 0%, #ff5600 100%);
  border-radius: 6px;
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}
.van-dialog__footer {
  margin: 0 auto;
  width: 680px;
}
.activeBlock {
  display: block;
  width: 150px;
  height: 70px;
  margin-left: 12px;
  border-radius: 5px;
  line-height: 70px;
  text-align: center;
  font-size: 34px;
  background: #df7233;
  color: #ffdec7;
  border-radius: 5px;
}
</style>
